import { useRoute } from "vue-router";

import { sendEmailAdmin } from "@/firebase/functions";

export function useErrorUtils() {
  const route = useRoute();

  async function sendError(error, context) {
    console.error(error);
    // console.log(error.message);
    // console.log(JSON.stringify(error.stack));
    // console.log(context);
    await sendEmailAdmin({
      userAgent: window.navigator.userAgent,
      locale: navigator.language,
      route: route?.fullPath ?? "",
      error: {
        message: error?.message ?? "",
        stack: JSON.stringify(error?.stack ?? ""),
        context: JSON.stringify(context)
      }
    });
  }

  return { sendError };
}
