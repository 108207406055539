import { functions } from "./config";
import { httpsCallable } from "firebase/functions";

export const appointmentCreate = httpsCallable(functions, "appointment-create");
export const appointmentCancel = httpsCallable(functions, "appointment-cancel");
export const appointmentConfirm = httpsCallable(functions, "appointment-confirm");
export const sendEmailAuthLink = httpsCallable(functions, "sendEmail-authLink");
export const sendEmailAdmin = httpsCallable(functions, "sendEmail-admin");
export const sendEmailCounselor = httpsCallable(functions, "sendEmail-counselor");
export const clinicRead = httpsCallable(functions, "clinic-read");
export const stripeCreateCheckoutSession = httpsCallable(functions, "stripe-createCheckoutSession");
export const stripeGetSessionStatus = httpsCallable(functions, "stripe-getSessionStatus");
export const userDelete = httpsCallable(functions, "user-delete");
export const vacanciesRead = httpsCallable(functions, "vacancies-read");
