import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "@/firebase/config";

import STATUS from "@/common/status.json";

// Navigation guard
// https://router.vuejs.org/guide/advanced/navigation-guards.html
const requireAuth = async (to, from, next) => {
  // console.debug("Current User", getAuth().currentUser);
  if (getAuth().currentUser) {
    // User is signed in, proceed to next route

    // If appointment is confirmed, redirect to booking-confirmed
    // if (!import.meta.env.DEV) { // START - skip in development mode
    if (to.name !== "booking-confirmed") {
      // redirect for all other pages
      const userDoc = await getDoc(doc(firestore, "users", getAuth().currentUser.uid));
      if (userDoc.exists()) {
        const appointmentId = userDoc.data().appointmentId;
        if (appointmentId) {
          const appointmentDoc = await getDoc(doc(firestore, "appointments", appointmentId));
          if (appointmentDoc.exists()) {
            const status = appointmentDoc.data().status;
            if (status.id === STATUS["confirmed"].id) {
              next({ name: "booking-confirmed" });
              return;
            }
          }
        }
      }
    }
    // }  // END - skip in development mode
    next();
  } else {
    // User is not signed in, redirect to login page
    next({ name: "login" });
  }
};

let routes = [
  {
    path: "/prenatal",
    redirect: "/?serviceId=prenatal"
  },
  {
    path: "/pgt",
    redirect: "/?serviceId=pgt"
  },
  {
    path: "/cancer",
    name: "service-cancer",
    redirect: "/?serviceId=cancer"
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/login.vue"),
    props: (route) => ({
      locale: route.query.locale,
      clinicId: route.query.clinicId,
      serviceId: route.query.serviceId
    })
  },
  {
    path: "/login-confirmation",
    name: "confirmation-auth-link",
    component: () => import("../views/confirmation/auth-link.vue")
  },
  {
    path: "/c/:clinicId",
    name: "clinic-id",
    component: () => import("../views/login.vue"),
    props: (route) => ({
      clinicId: route.params.clinicId
    })
  },
  {
    path: "/b/clinic-id",
    alias: ["/b/"],
    name: "booking-clinic-id",
    component: () => import("../views/booking/clinic-id.vue"),
    props: (route) => ({
      id: route.query.id
    }),
    beforeEnter: requireAuth
  },
  {
    path: "/b/patient-id",
    name: "booking-patient-id",
    component: () => import("../views/booking/patient-id.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/contact-info",
    name: "booking-contact-info",
    component: () => import("../views/booking/contact-info.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/profile",
    name: "booking-profile",
    component: () => import("../views/booking/profile.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/family",
    name: "booking-family",
    component: () => import("../views/booking/family.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/fertility-treatment",
    name: "booking-fertility-treatment",
    component: () => import("../views/booking/fertility-treatment.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/pregnancy-current",
    name: "booking-pregnancy-current",
    component: () => import("../views/booking/pregnancy-current.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/pregnancy-history",
    name: "booking-pregnancy-history",
    component: () => import("../views/booking/pregnancy-history.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/prenatal-testing",
    name: "booking-prenatal-testing",
    component: () => import("../views/booking/prenatal-testing.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/expectations",
    name: "booking-expectations",
    component: () => import("../views/booking/expectations.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/appointment",
    name: "booking-calendar",
    component: () => import("../views/booking/calendar.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/review",
    name: "booking-review",
    component: () => import("../views/booking/review.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/checkout",
    name: "booking-checkout",
    component: () => import("../views/booking/checkout.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/payment-confirmed",
    name: "booking-payment-confirmed",
    component: () => import("../views/booking/payment-confirmed.vue"),
    props: (route) => ({
      sessionId: route.query.sessionId
    }),
    beforeEnter: requireAuth
  },
  {
    path: "/b/confirmed",
    name: "booking-confirmed",
    component: () => import("../views/booking/confirmed.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/b/pgt-reports",
    name: "booking-ovus",
    component: () => import("../views/booking/ovus.vue"),
    beforeEnter: requireAuth
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
    props: (route) => ({
      id: route.query.id,
      name: route.query.name,
      email: route.query.email
    })
  },
  {
    path: "/contact-confirmation",
    name: "confirmation-contact",
    component: () => import("../views/confirmation/contact.vue")
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/error.vue")
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404.vue")
  }
];

/**
 * Development routes
 * Add development routes only in development mode
 */
if (import.meta.env.DEV) {
  const devRoutes = [
    {
      path: "/_/_/dev/calendar",
      name: "dev-calendar",
      component: () => import("../views/dev/calendar.vue")
    },
    {
      path: "/_/_/dev/code",
      name: "dev-code",
      component: () => import("../views/dev/code.vue")
    },
    {
      path: "/_/_/dev/css",
      name: "dev-css",
      component: () => import("../views/dev/css.vue")
    },
    {
      path: "/_/_/dev/date",
      name: "dev-date",
      component: () => import("../views/dev/date.vue")
    },
    {
      path: "/_/_/dev/formkit-schema",
      name: "dev-formkit-schema",
      component: () => import("../views/dev/formkit-schema.vue")
    },
    {
      path: "/_/_/dev/layout",
      name: "dev-layout",
      component: () => import("../views/dev/layout.vue")
    },
    {
      path: "/_/_/dev/logo-animation",
      name: "dev-logo-animation",
      component: () => import("../views/dev/logo-animation.vue")
    },
    {
      path: "/_/_/dev/modal",
      name: "dev-modal",
      component: () => import("../views/dev/modal.vue")
    },
    {
      path: "/_/_/dev/test-error",
      name: "dev-test-error",
      component: () => import("../views/dev/test-error.vue")
    },
    {
      path: "/_/_/dev/timezone",
      name: "dev-timezone",
      component: () => import("../views/dev/timezone.vue")
    }
  ];

  routes = routes.concat(devRoutes);
}

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    return { top: 0 };
  }
});

export default router;
