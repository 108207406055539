import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { doc, onSnapshot, updateDoc, serverTimestamp } from "firebase/firestore";

import { firestore } from "@/firebase/config";
import { appointmentCreate } from "@/firebase/functions";

import { useErrorUtils } from "@/composables/utils-error";

import { isLocaleSupported } from "@/common/locale/languages";

export const useAppointmentStore = defineStore("store", () => {
  const DEFAULT_LOCALE = "ja";
  const BROWSER_LOCALE = navigator.language.slice(0, 2);

  const { sendError } = useErrorUtils();

  // Snapshot unsubscribers
  let _unsubscribeAppointmentSnapshot;
  let _unsubscribeClinicSnapshot;
  let _unsubscribeServiceSnapshot;
  let _unsubscribeUserSnapshot;

  // User states
  const _userId = ref("");
  const user = ref({});
  const locale = ref(isLocaleSupported(BROWSER_LOCALE) ? BROWSER_LOCALE : DEFAULT_LOCALE);

  // Appointment states
  const _appointmentId = ref("");
  const appointment = ref({});

  // Clinic states
  const _clinicId = ref("");
  const clinic = ref({});

  // Service states
  const _serviceId = ref("");
  const service = ref({});

  /**
   * Reset store
   */
  function $reset() {
    if (_unsubscribeAppointmentSnapshot) {
      _unsubscribeAppointmentSnapshot();
    }
    if (_unsubscribeClinicSnapshot) {
      _unsubscribeClinicSnapshot();
    }
    if (_unsubscribeServiceSnapshot) {
      _unsubscribeServiceSnapshot();
    }
    if (_unsubscribeUserSnapshot) {
      _unsubscribeUserSnapshot();
    }

    // Reset states
    _appointmentId.value = "";
    _clinicId.value = "";
    _serviceId.value = "";
    _userId.value = "";
    appointment.value = {};
    clinic.value = {};
    service.value = {};
    user.value = {};
    locale.value = isLocaleSupported(BROWSER_LOCALE) ? BROWSER_LOCALE : DEFAULT_LOCALE;
  }

  watch(_appointmentId, async () => {
    if (_appointmentId.value) {
      // Unsubscribe to the previous snapshot if it exists
      if (_unsubscribeAppointmentSnapshot) {
        _unsubscribeAppointmentSnapshot();
      }

      _unsubscribeAppointmentSnapshot = onSnapshot(
        doc(firestore, "appointments", _appointmentId.value),
        async (doc) => {
          if (doc.exists) {
            const data = doc.data();
            appointment.value = data;
            _clinicId.value = data?.clinicId ?? "";
            _serviceId.value = data?.serviceId ?? "";
          }
        }
      );
    }
  });

  watch(_clinicId, async () => {
    if (_clinicId.value) {
      // Unsubscribe to the previous snapshot if it exists
      if (_unsubscribeClinicSnapshot) {
        _unsubscribeClinicSnapshot();
      }

      _unsubscribeClinicSnapshot = onSnapshot(
        doc(firestore, "clinics", _clinicId.value),
        async (doc) => {
          if (doc.exists) {
            clinic.value = doc.data();
          }
        }
      );
    }
  });

  watch(_serviceId, async () => {
    if (_serviceId.value) {
      // Unsubscribe to the previous snapshot if it exists
      if (_unsubscribeServiceSnapshot) {
        _unsubscribeServiceSnapshot();
      }

      _unsubscribeServiceSnapshot = onSnapshot(
        doc(firestore, "services", _serviceId.value),
        async (doc) => {
          if (doc.exists) {
            service.value = doc.data();
          }
        }
      );
    }
  });

  watch(_userId, async () => {
    if (_userId.value) {
      // Unsubscribe to the previous snapshot if it exists
      if (_unsubscribeUserSnapshot) {
        _unsubscribeUserSnapshot();
      }

      _unsubscribeUserSnapshot = onSnapshot(doc(firestore, "users", _userId.value), async (doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (!data) {
            return;
          }

          user.value = data;
          if (data.appointmentId) {
            _appointmentId.value = data.appointmentId;
          } else {
            // If user does not have an appointment record, create one
            console.debug("Create new appointment record");
            try {
              const resp = await appointmentCreate();
              _appointmentId.value = resp.data;
            } catch (error) {
              await sendError(error, "Failed to create new appointment record");
            }
          }
          console.debug("Appointment ID", _appointmentId.value);

          if (data.locale) {
            locale.value = isLocaleSupported(data.locale) ? data.locale : DEFAULT_LOCALE;
          }
        }
      });
    }
  });

  /**
   * Register user into the store
   * @param {String} userId User ID
   * @param {Object} userData Firebase data object
   */
  function init(userId) {
    _userId.value = userId;
  }

  /**
   * Update store data
   * @param {Object} data Firebase data object
   * @returns
   */
  async function update(collection, data) {
    switch (collection) {
      case "appointments": {
        await updateDoc(doc(firestore, collection, _appointmentId.value), {
          updated: serverTimestamp(),
          ...data
        });
        break;
      }
      case "users": {
        await updateDoc(doc(firestore, collection, _userId.value), {
          updated: serverTimestamp(),
          ...data
        });
        break;
      }
      default:
        console.error("Invalid collection name", collection);
        break;
    }
  }

  return {
    appointmentId: _appointmentId,
    clinicId: _clinicId,
    serviceId: _serviceId,
    userId: _userId,
    appointment,
    clinic,
    service,
    user,
    locale,
    $reset,
    init,
    update
  };
});
